import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsTableModule } from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DeltaCellComponent } from './components/delta-cell/delta-cell.component';
import { MonthYearPickerComponent } from './components/month-year-picker/month-year-picker.component';
import { PartnerMetadataComponent } from './components/partner-metadata/partner-metadata.component';
import { SelectUserDialogComponent } from './components/select-user-dialog/select-user-dialog.component';
import { StringEnumeratorComponent } from './components/string-enumerator/string-enumerator.component';
import { BreakProductNamePipe } from './pipes/break-product-name.pipe';
import { ProductLinePipe } from './pipes/product-line.pipe';
import { RegionPipe } from './pipes/region.pipe';
import { SelectUserDialogEffects } from './store/select-user-dialog.effects';
import * as fromSelectUserDialog from './store/select-user-dialog.reducer';
import { UsernamePipe } from './pipes/username.pipe';

@NgModule({
  declarations: [
    PartnerMetadataComponent,
    SelectUserDialogComponent,
    ConfirmDialogComponent,
    StringEnumeratorComponent,
    MonthYearPickerComponent,
    BreakProductNamePipe,
    DeltaCellComponent,
    RegionPipe,
    ProductLinePipe,
    UsernamePipe,
  ],
  exports: [
    StringEnumeratorComponent,
    SelectUserDialogComponent,
    PartnerMetadataComponent,
    MonthYearPickerComponent,
    BreakProductNamePipe,
    DeltaCellComponent,
    RegionPipe,
    ProductLinePipe,
    DsFilterModule,
    UsernamePipe,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSelectUserDialog.featureName,
      fromSelectUserDialog.reducer,
    ),
    PdLetDirectiveModule,
    FormsModule,
    EffectsModule.forFeature([SelectUserDialogEffects]),
    DsSpacingModule,
    DsPageModule,
    FlexLayoutModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    DsSnackbarComponent,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatDialogModule,
    MatIconModule,
    DsTableModule,
    DsTextIndicatorModule,
    DsFilterInputModule,
    TranslateModule,
    DsPlaceholderModule,
  ],
})
export class SharedModule {}
